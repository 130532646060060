<template>
  <div>
    <div class="card-toolbar mb-5">
<!--      <router-link v-if="$can('document_status_paths.create')" to="/settings/document-status-paths/create"  class="btn btn-primary font-weight-bolder">-->
<!--        <v-icon>mdi-plus</v-icon> {{ $t('document_status_paths.add_path') }}-->
<!--      </router-link>-->
      <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder ml-5">
        <span><i class="la la-filter"></i> <span>{{ $t('advanced_filter') }}</span></span>
      </button>
    </div>

    <div class="card card-custom mb-5" v-if="showAdvancedSearch">
      <div class="card-body">
        <div class="m-form m-form--fit m--margin-bottom-20">
          <div class="row">
            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_name">{{ $t('status_mapping.module') }}</label>
              <select type="text" v-model="filters.module_id" class="custom-select" :class="validation && validation.module_id ? 'is-invalid' : ''">
                <option v-for="(row, index) in module_type_list" :value="row.id" :key="'module_type'+index">{{row.name}}</option>
              </select>
            </div>

            <div class="form-group col-md-6 mt-2 mb-2">
              <label for="f_status">
                {{ $t('status') }}
              </label>
              <select name="" id="f_status" v-model="filters.is_active" type="text" class="custom-select">
                <option value="">{{ $t('all') }}</option>
                <option v-for="(row, index) in status_list" :value="row.id" :key="index">{{ row.text }}</option>
              </select>
            </div>
            <div class="form-group d-inline-flex col-md-6 mt-10">
              <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                                <span><i class="fas fa-search"></i>
                                    <span>{{ $t('search') }}</span>
                                </span>
              </button>
              <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="m_reset">
                                <span><i class="fas fa-trash-restore"></i>
                                    <span>{{ $t('reset_search') }}</span>
                                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::User-->
    <div class="card card-custom">
      <div class="card-body">
        <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

          <template slot="mobile" slot-scope="props">
            <span class="mobile-style-ltr w-100-center">{{ props.row.mobile }}</span>
          </template>
          <template slot="is_active" slot-scope="props">
            <b-form-checkbox size="lg" v-if="$can('status_mapping.change_status')" @change="changeStatus(props.row.id, props.row.is_active)" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
            <b-form-checkbox size="lg" v-else :disabled="true" v-model="props.row.is_active" :name="'check-button'+props.row.id" switch :key="props.row.id"></b-form-checkbox>
          </template>
          <template slot="actions" slot-scope="props">
            <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" v-if="$can('status_mapping.update')" color="blue darken-2" @click="editItem(props.row)">mdi-pencil</v-icon>
            <v-icon small class="mr-2 text-danger" v-b-tooltip.hover :title="$t('delete')" v-if="$can('status_mapping.delete')" color="red darken-2" @click="deleteItem(props.row)">mdi-delete</v-icon>

          </template>
        </v-server-table>


        <!--end: Datatable-->
      </div>
    </div>
    <!--end::User-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Vue from "vue";

export default {
  name: "index",
  components: {},
  data() {
    return {
      mainRoute: 'settings/status_mapping',
      subMainRoute: 'settings/stat_mapping',
      mainRouteDependency: 'base/dependency',

      showAdvancedSearch: false,
      filters: {
        module_id: '',
        is_active: '',
      },
      status_list: [
        {id: 1, text: this.$t('active')},
        {id: 0, text: this.$t('inactive')},
      ],
      columns: ['name','module_name', 'document_name', 'is_active', 'actions'],


      disabledButton: false,
      validation: [],
      dataList: [],
      module_type_list: [],
    }
  },
  computed: {

    options: function () {
      let that = this;
      return {
        texts: {
          loadingError: that.$t('Something_went_wrong'),
          filter: "",
          limit: that.$t('records'),
          filterBy: that.$t('Filter') + ' {column}',
          count: ' ',
          filterPlaceholder: that.$t('Search_query'),
          loading: that.$t('Loading') + "...",
        },
        headings: {
          name: that.$t('basic_status_management.name'),
          module_name: that.$t('status_mapping.module'),
          document_name: that.$t('status_mapping.document'),
          is_active: that.$t('status'),
          actions: that.$t('actions'),

        },
        sortable: ['name'],
        filterByColumn: false,
        filterable: false,
        customFilters: [{
          name: 'alphabet',

        }],
        orderBy: {'column': 'created_at'},

        alwaysShowPerPageSelect: true,
        perPage: 10,
        pagination: {chunk: 5, dropdown: false},
        skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
        sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
        perPageValues: [5, 10, 15, 25],

        requestFunction(data) {
          let _params = {
            ascending: data.ascending,
            byColumn: data.byColumn,
            limit: data.limit,
            orderBy: data.orderBy,
            page: data.page,
            filter: data.query,
            ...that.filters,
          }
          return ApiService.query(that.mainRoute, {..._params});

        },
        responseAdapter(resp) {
          that.dataList = resp.data.data.data;
          return {
            data: resp.data.data.data,
            count: resp.data.data.total,
          }
        }
      }

    },
  },

  mounted() {
    // let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.status_mapping")}]);
    this.getModuleType();
  },
  methods: {
    getFetch() {
      this.$refs.table.refresh();
    },
    openAdvancedSearch() {
      this.showAdvancedSearch = !this.showAdvancedSearch;
    },
    doFilter() {
      this.$refs.table.refresh();
    },
    resetFilter() {
      this.filters.name = null;
      this.filters.is_active = null;

      this.$refs.table.refresh();
    },

    async getModuleType() {
      await ApiService.get(this.mainRouteDependency+"/modules").then((response) => {
        this.module_type_list = response.data.data
      });
    },

    actionDelete(item) {
      ApiService.delete(this.mainRoute + "/" + item.id).then((response) => {
        this.getFetch();
        this.$successAlert(response.data.message)
      }).catch((error) => {
        this.$errorAlert(error)
      })
    },
    editItem(item) {
      this.$router.push({name: 'status-mapping.edit', params: {id: item.id}});
    },
    deleteItem(item) {
      this.$confirmAlert('', this.actionDelete, item);
    },

    changeStatus(id, status) {
      ApiService.patch(this.subMainRoute + '/change-status/' + id, {
        is_active: (status ? 1 : 0),
      }).then(response => {
        this.$refs.table.refresh();
        this.$successAlert(response.data.message);
      }).catch(error => {
        this.$errorAlert(error);
      });
    },
  },
};
</script>
